<template>
  <v-container fluid>
    <peeps-alert-animation ref="animationGlobal" message="Parcel update was Successful!">
    </peeps-alert-animation>
    <card-view :highlight="highlight" @update="onUpdate"></card-view>
  </v-container>
</template>

<script>
export default {
  components: {
    CardView: () => import('../../components/Parcel/CardView.vue'),
    PeepsAlertAnimation: () => import('@/components/Core/Alerts/PeepsAlertAnimation.vue')
  },
  data() {
    return {
      highlighted: false,
      highlight: ''
    };
  },
  mounted() {
    this.highlightParcel();
    this.$store.commit('viewMode', 'card');
  },
  methods: {
    highlightParcel() {
      this.$bus.$on('parcelLoad', () => {
        if (this.highlighted) {
          return;
        }
        this.highlighted = true;
        const parcel = this.$route.query.highlight;
        if (parcel) {
          this.highlight = parcel;
        }
      });
    },
    animate() {
      this.$refs.animationGlobal.animate();
    },
    onUpdate() {
      this.animate();
    }
  }
};
</script>

<style>
</style>
